<template>
  <Popup @close="close" className="_center">
    <img src="img/attention-icon.svg" alt="" />
    <h2>
      {{ title }}
    </h2>
    <p class="text" v-if="text">{{ text }}</p>
    <div class="attention__actions">
      <Button
        text="Да"
        className="btn-primary"
        class="_filled"
        @click="apply"
      />
      <Button text="Отмена" class="_outlined" @click="close" />
    </div>
  </Popup>
</template>

<script>
import Popup from "./Popup";
import Button from "./Button";

export default {
  components: {
    Button,
    Popup,
  },
  props: {
    title: String,
    text: String,
  },
  emits: ["close", "apply"],
  methods: {
    close() {
      this.$emit("close");
    },
    apply() {
      this.$emit("apply");
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
}

.text {
  margin-top: 15px;
}

img {
  margin: 0 auto 10px;
}

.attention__actions {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: 50px;

  .btn {
    flex: 0 0 175px;
  }
}
</style>
