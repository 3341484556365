
import { defineComponent } from "@vue/runtime-core";
import AppPage from "@/components/AppPage.vue";
import TipTap from "@/components/TipTap.vue";
import Button from "@/components/Button.vue";
import AddFaqPopup from "./AddFaqPopup.vue";
import TextInput from "@/components/TextInput.vue";
import Pagination from "@/components/Pagination.vue";
import AttentionPopup from "@/components/AttentionPopup.vue";
import { VueDraggableNext } from "vue-draggable-next";

import axios from "axios";
import xss, { IWhiteList } from "xss";

interface IDataModel {
  activeIndex: number | null;
  faqItems: Array<FaqItem>;
  totalRows: number | null;
  editingTitle: string;
  editingTitleIndex: number | null;
  content: string;
  editorVisibleIndex: number | null;
  faqItem: FaqItem | null;
  errors: any;
  isEditBtnVisible: boolean;
  editBtnIndex: number | null;
  filter: IQuery;
  isAttentionShowed: boolean;
  deletingFaqItemIndex: number | null;
}

interface IQuery {
  search: string;
  pagination: any;
}

interface FaqItem {
  id: number | undefined;
  title: string;
  body: any;
  order?: number;
  hasChanged?: any;
}

export default defineComponent({
  components: {
    AppPage,
    TipTap,
    Button,
    AddFaqPopup,
    TextInput,
    Pagination,
    AttentionPopup,
    draggable: VueDraggableNext,
  },
  data(): IDataModel {
    return {
      activeIndex: null,
      faqItems: [],
      editingTitle: "",
      editingTitleIndex: null,
      content: "",
      editorVisibleIndex: null,
      faqItem: null,
      errors: {} as { [key: string]: string | null },
      isEditBtnVisible: false,
      editBtnIndex: null,
      filter: {
        search: "",
        pagination: { limit: 10, offset: 0 },
      },
      totalRows: null,
      isAttentionShowed: false,
      deletingFaqItemIndex: null,
    };
  },
  created() {
    this.load();
  },
  mounted: function () {
    this.$watch("filter.pagination", this.load);
  },
  methods: {
    async swapOrder({ moved }: any) {
      const newList = [...this.faqItems].map((item, index) => {
        const newSort = index + this.filter.pagination.offset;
        item.hasChanged = item.order !== newSort;
        if (item.hasChanged) {
          item.order = newSort;
        }
        return item;
      });
      const changedItems = newList
        .filter((item: FaqItem) => item.hasChanged)
        .map((item: FaqItem) => ({
          id: item.id,
          order: item.order,
        }));
      await axios.post("/api/faq/update-order", changedItems);
      this.faqItems = newList;
    },
    addFaq() {
      this.faqItem = {
        id: undefined,
        title: "",
        body: "",
      };
    },
    onDeleteFaqItem(id: number) {
      this.deletingFaqItemIndex = id;
      this.isAttentionShowed = true;
    },
    close() {
      this.faqItem = null;
      this.isAttentionShowed = false;
      this.deletingFaqItemIndex = null;
    },
    onTitleOver(id: number) {
      this.editBtnIndex = id;
      this.isEditBtnVisible = true;
    },
    onTitleLeave() {
      this.editBtnIndex = null;
      this.isEditBtnVisible = false;
    },
    editTitle(id: number, value: string) {
      this.editingTitle = value;
      this.editingTitleIndex = id;
    },
    editContent(id: number, value: string) {
      this.content = value;
      this.editorVisibleIndex = id;
    },
    cancel() {
      this.editorVisibleIndex = null;
      this.content = "";
    },
    showFaqItemBody(id: number) {
      if (this.activeIndex === id) {
        this.activeIndex = null;
        return;
      }

      this.activeIndex = id;
      this.editorVisibleIndex = null;
      this.content = "";
    },
    updateFilter(e: any) {
      this.editorVisibleIndex = null;
      this.content = "";
      this.editingTitle = "";
      this.editingTitleIndex = null;
      this.editBtnIndex = null;
      this.activeIndex = null;
      this.filter = { ...this.filter, pagination: e };
    },
    async load() {
      this.faqItems = [];
      this.totalRows = null;
      this.editorVisibleIndex = null;
      this.content = "";
      this.editingTitle = "";
      this.editingTitleIndex = null;
      this.editBtnIndex = null;

      try {
        const { data } = await axios.get("/api/faq", {
          params: { q: JSON.stringify(this.filter) },
        });
        this.faqItems = data.rows;
        this.totalRows = data.total;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
    },
    async deleteFaqItem(id: number) {
      try {
        await axios.post("/api/faq/delete/" + id);
        this.load();
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
    async save(id: number) {
      let item = this.faqItems.find((item) => item.id === id) || {
        title: "",
        body: "",
      };
      item.title =
        this.editingTitle.length > 1 ? this.editingTitle : item.title;
      item.body = this.content.length > 1 ? this.content : item.body;

      try {
        const sanitized = xss(item.title, {
          whiteList: {} as IWhiteList,
        });

        await axios.post("/api/faq", {
          ...item,
          title: sanitized,
        });
        this.load();
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
});
