<template>
  <popup className="_center" @close="close">
    <h2 class="editor__title">Добавить руководство</h2>
    <div class="popup__faq--content">
      <div class="popup__faq--title-editing">
        <TextInput
          label="Заголовок"
          subClassName="full-width"
          placeholder="Введите заголовок"
          :value="faqItem.title"
          @update:value="emitPatch({ title: $event })"
          :required="true"
          :error="errors.title"
        />
      </div>
      <div class="popup__faq--body-editing">
        <p>Контент <span>*</span></p>
        <TipTap v-model="body" />
        <span class="error">{{ errors.body }}</span>
      </div>
      <div
        class="popup__faq--preview"
        v-if="isPreviewShowed"
        v-html="faqItem.body"
      />
      <div class="popup__faq--actions">
        <Button
          class="btn-primary"
          className="_filled"
          text="Добавить"
          @click="save"
        />
        <Button className="_outlined" text="Предпросмотр" @click="preview" />
      </div>
    </div>
  </popup>
</template>

<script>
import Popup from "@/components/Popup";
import TextInput from "@/components/TextInput";
import TipTap from "@/components/TipTap";
import Button from "@/components/Button";

import axios from "axios";
import * as xss from "xss";

export default {
  components: {
    Popup,
    TextInput,
    TipTap,
    Button,
  },
  props: {
    faqItem: Object,
  },
  data() {
    return {
      body: this.faqItem.body,
      errors: {},
      isPreviewShowed: false,
    };
  },
  emits: ["update:faq-item", "close", "save"],
  watch: {
    body(value) {
      this.$emit("update:faq-item", {
        ...this.faqItem,
        body: value,
      });
      this.errors.body = "";
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    preview() {
      this.isPreviewShowed = true;
    },
    emitPatch(patch) {
      this.$emit("update:faq-item", {
        ...this.faqItem,
        ...patch,
      });

      for (const k of Object.keys(patch)) {
        this.errors[k] = null;
      }
    },
    async save() {
      try {
        const sanitized = xss(this.faqItem.title, {
          whiteList: [],
        });

        const item = {
          ...this.faqItem,
          title: sanitized,
        };
        await axios.post("/api/faq", item);
        this.$emit("save");
        this.$emit("close");
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.popup__faq {
  &--content {
    margin-top: 30px;
  }

  &--title-editing {
    textarea {
      border: 1px solid $color-blue-light;
      padding: 15px;
      border-radius: 4px;
      width: 100%;
    }
  }

  &--body-editing {
    margin-top: 30px;

    p {
      font-size: 14px;
      line-height: 16px;
      color: #00004f;
      margin-bottom: 8px;

      span {
        color: red;
      }
    }
  }

  &--actions {
    margin-top: 30px;
    display: flex;
    gap: 30px;

    > button {
      flex: 1 1 50%;
      max-width: 50%;
    }
  }

  &--preview {
    margin-top: 30px;
  }
}
</style>
