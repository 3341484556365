<template>
  <div class="tiptap__actions">
    <div class="tiptap__actions--textstyling">
      <button
        type="button"
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ active: editor && editor.isActive('bold') }"
      >
        <i class="icon icon-bold-text"></i>
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ active: editor && editor.isActive('italic') }"
      >
        <i class="icon icon-italic-text"></i>
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleUnderline().run()"
        :class="{ active: editor && editor.isActive('underline') }"
      >
        <i class="icon icon-underline-text"></i>
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleStrike().run()"
        :class="{ active: editor && editor.isActive('strike') }"
      >
        <i class="icon icon-strikethrough-text"></i>
      </button>
    </div>
    <div class="editor__actions--list">
      <button
        type="button"
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ active: editor && editor.isActive('bulletList') }"
      >
        <i class="icon icon-list_bullet"></i>
      </button>
      <button
        type="button"
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ active: editor && editor.isActive('orderedList') }"
      >
        <i class="icon icon-list_number"></i>
      </button>
    </div>
    <div class="editor__actions--modify">
      <button
        type="button"
        @click="editor.chain().focus().toggleBlockquote().run()"
        :class="{ active: editor && editor.isActive('blockquote') }"
      >
        <i class="icon icon-quote"></i>
      </button>
      <button type="button" @click="setLink">
        <i class="icon icon-link"></i>
      </button>
      <button type="button">
        <label>
          <i class="icon icon-img"></i>
          <input type="file" accept="image/*" @change="addImage" />
        </label>
      </button>
    </div>
    <div class="tiptap__actions--color">
      <label
        class="color"
        :style="{
          backgroundColor:
            editor && editor.getAttributes('textStyle').color
              ? editor.getAttributes('textStyle').color
              : defaultColor,
        }"
      >
        <input
          type="color"
          @input="
            editor && editor.chain().focus().setColor($event.target.value).run()
          "
          :value="
            (editor && editor.getAttributes('textStyle').color) || defaultColor
          "
        />
      </label>
    </div>
    <div class="tiptap__fontsize" v-if="fontSizeDropdown">
      <div
        class="tiptap__fontsize--header"
        @click="isFontSizeVisible = !isFontSizeVisible"
        :class="{ active: isFontSizeVisible }"
      >
        {{ selectedFontSize || "Размер шрифта" }}
        <span class="icon"></span>
      </div>
      <div class="tiptap__fontsize--body" v-if="isFontSizeVisible">
        <div
          class="tiptap__fontsize--item"
          @click="selectFontSize(fs.fontSize)"
          :class="{
            active: editor.isActive('textStyle', { fontSize: fs.fontSize }),
          }"
          v-for="(fs, i) in fontsizeList"
          :key="i"
        >
          {{ fs.fontSize }}
        </div>
      </div>
    </div>
    <div>
      <div class="tiptap__clear">
        <button type="button" @click="transformPastedHTML" class="clear">
          Очистить ссылку
        </button>
      </div>
    </div>
  </div>
  <editor-content :editor="editor" />
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Image from "@tiptap/extension-image";
import { Color } from "@tiptap/extension-color";
import { FontSize } from "tiptap-extension-font-size";

export default {
  components: {
    EditorContent,
  },
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    fontSizeDropdown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editor: null,
      defaultColor: "#00004f",
      image: null,
      isFontSizeVisible: false,
      selectedFontSize: null,
      fontsizeList: [
        { fontSize: "12px" },
        { fontSize: "14px" },
        { fontSize: "16px" },
        { fontSize: "18px" },
        { fontSize: "24px" },
      ],
    };
  },
  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }
      this.editor.commands.setContent(value, false);
    },
  },
  methods: {
    async imageToBlob(image) {
      return image && (await (await fetch(image)).blob());
    },
    addImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.image = event.target?.result;
          this.editor.chain().focus().setImage({ src: this.image }).run();
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    selectFontSize(fs) {
      this.editor.chain().focus().setFontSize(fs).run();
      this.selectedFontSize = fs;
      this.isFontSizeVisible = false;
    },
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);
      // cancelled
      if (url === null) {
        return;
      }
      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    transformPastedHTML() {
      this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.modelValue,
      extensions: [
        StarterKit,
        Document,
        Text,
        TextStyle,
        Underline,
        FontSize,
        Color,
        Link.configure({
          openOnClick: true,
        }),
        Image.configure({
          allowBase64: true,
        }),
      ],
      onUpdate: () => {
        if (this.editor.state.doc.textContent.length === 0) {
          this.$emit("update:modelValue", "");
          return;
        }

        this.$emit("update:modelValue", this.editor.getHTML());
      },
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
@import "src/styles/vars";

.ProseMirror {
  padding: 10px;
  border: 1px solid fade-out($color-blue-light, 0.5);
  min-height: 200px;

  > * + * {
    margin-top: 10px;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  a {
    cursor: pointer;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  blockquote {
    margin: 0;
  }

  blockquote p {
    padding: 15px;
    background: #eee;
    border-radius: 5px;
  }

  blockquote p::before {
    content: "\201C";
  }

  blockquote p::after {
    content: "\201D";
  }

  a {
    color: $color-blue-light;
  }

  img {
    max-width: 100%;
  }
}

.tiptap {
  &__fontsize {
    width: 140px;

    &--header {
      padding: 6px 8px;
      background: rgba(167, 167, 245, 0.15);
      border-radius: 2px;
      cursor: pointer;
      width: 100%;
      font-size: 14px;
    }

    &--item {
      transition: background-color 0.2s ease, color 0.2s ease;
      padding: 6px 14px;
      cursor: pointer;

      &:hover {
        background-color: #3a58ff;
        color: #fff;
      }
    }

    &--body {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      z-index: 2;
      width: 100%;
      max-height: 150px;
      overflow-y: auto;
      background-color: #fff;
      border-radius: 4px;

      &::-webkit-scrollbar {
        width: 3px;
        background: #eaeeff;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #cdd1f3;
      }

      &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 30px;
    padding: 10px;
    background: #f6f8ff;
    border: 1px solid #5378fb;
    border-bottom: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;

    &--color {
      width: 24px;
      height: 24px;
    }

    .icon {
      &::before {
        margin: 0;
        width: 100%;
      }
    }

    > div {
      display: flex;
      gap: 5px;
    }

    button {
      width: 28px;
      height: 24px;
      background: rgba(167, 167, 245, 0.15);
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid transparent;

      &.clear {
        width: auto;
        font-size: 14px;
      }

      label {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        input {
          width: 0;
          height: 0;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      &.active {
        border-color: $color-blue-light;
      }
    }
  }
}
</style>
